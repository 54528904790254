import React, { useState, useEffect } from "react";
import PostCard from "./PostCard";
import blogData from "../static/BlogData";
import "../assets/styles/Site.scss";
import axios from "axios";
import { Grid } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import sortAndSetCategory from "../utils/SortAndSetCategeory";
// wrapper for items
const styles = makeStyles((muiBaseTheme) => ({
  paginator: {
    justifyContent: "center",
    padding: "10px",
  },
}));

const compare = (a, b) => {
  if (a.pubDate < b.pubDate) {
    return 1;
  }
  if (a.pubDate > b.pubDate) {
    return -1;
  }
  return 0;
};

const Slider = () => {
  const [mediumPostList, setMediumPostList] = useState([]);
  const [devPostList, setDevPostList] = useState([]);
  const [page, setPage] = useState(1);
  const [sortedPosts, setSortedPosts] = useState([]);
  const [noOfPages, setNoOfPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 4;

  const classes = styles();
  // const state = {
  //   avatar: "",
  //   profileLink: "",
  //   mediumPostList: [],
  //   devPostList: [],
  //   page: 1,
  //   sortedPosts: [],
  //   itemsPerPage: 4,
  //   noOfPages: 0,
  // };

  const mediumURL = `https://api.rss2json.com/v1/api.json?rss_url=https://${blogData.MediumUserName}.medium.com/feed`;
  const blogURL = `https://api.rss2json.com/v1/api.json?rss_url=https://dev.to/feed/${blogData.DevToUserName}`;

  useEffect(() => {
    async function fetchPostDetails() {
      await axios
        .get(mediumURL)
        .then(async (response) => await response.data)
        .then((data) => {
          // console.log(data);
          const {
            feed: { image = "", link },
            items,
          } = data || { feed: {} };

          const posts = items.filter((item) => item?.categories?.length > 0);
          const tagArrays = posts.map((item) => {
            return item?.categories;
          });

          const allTags = tagArrays.flat();

          const sortedTagsArray = sortAndSetCategory(allTags) || [];

          const tagArticle = [];
          let removedBlogs = posts;

          for (let i = 0; i < sortedTagsArray.length; ++i) {
            const blogsWithTag = removedBlogs.filter((blog) =>
              blog.categories.includes(sortedTagsArray[i])
            ); //filter

            removedBlogs = removedBlogs.filter(
              (blog) => blogsWithTag.indexOf(blog) === -1
            ); //exclude

            if (blogsWithTag.length > 0) {
              blogsWithTag.forEach((item) => {
                item.tag = sortedTagsArray[i];
                tagArticle.push(item);
              });
            }
          }

          const filteredTagArrays = tagArticle.map((item) => {
            return item.tag;
          });

          const filteredSortedTagsArray =
            sortAndSetCategory(filteredTagArrays) || [];

          tagArticle.forEach((item) => {
            item.tagNo = filteredSortedTagsArray.indexOf(item.tag) + 1;
            item.avatar = image; // push avatar inside the json
            item.profileLink = link; // push profile link inside the JSON
            item.source = "Medium";
          });

          setMediumPostList(tagArticle);
        })
        .catch((err) =>
          console.log(err, "Occured while fetching Medium posts")
        );

      await axios
        .get(blogURL)
        .then(async (response) => await response.data)
        .then((data) => {
          const {
            feed: { image = "", link },
            items,
          } = data || {};
          const posts = items.filter((item) => item?.categories?.length > 0);
          const tagArrays = posts.map((item) => {
            return item.categories;
          });

          const allTags = tagArrays.flat();

          const sortedTagsArray = sortAndSetCategory(allTags) || [];

          const tagArticle = [];
          let removedBlogs = posts;

          for (let i = 0; i < sortedTagsArray.length; ++i) {
            const blogsWithTag = removedBlogs.filter((blog) =>
              blog.categories.includes(sortedTagsArray[i])
            ); //filter

            removedBlogs = removedBlogs.filter(
              (blog) => blogsWithTag.indexOf(blog) === -1
            ); //exclude

            if (blogsWithTag.length > 0) {
              blogsWithTag.forEach((item) => {
                item.tag = sortedTagsArray[i];
                tagArticle.push(item);
              });
            }
          }

          const filteredTagArrays = tagArticle.map((item) => {
            return item.tag;
          });

          const filteredSortedTagsArray =
            sortAndSetCategory(filteredTagArrays) || [];

          tagArticle.forEach((item) => {
            item.tagNo = filteredSortedTagsArray.indexOf(item.tag) + 101;
            item.avatar = image; // push avatar inside the json
            item.profileLink = link; // push profile link inside the JSON
            item.source = "Dev";
          });

          setDevPostList(tagArticle);
        })
        .catch((err) => console.log(err, "Occured while fetching Dev posts"));
    }
    fetchPostDetails();
  }, []);

  useEffect(() => {
    let postList = [];
    let sortPostlist;
    if (mediumPostList.length > 0 && devPostList.length > 0) {
      postList.push(...mediumPostList, ...devPostList);
      sortPostlist = postList.sort(compare);
      setSortedPosts(sortPostlist);
    }
  }, [mediumPostList, devPostList]);

  useEffect(() => {
    setLoading(false);
    setNoOfPages(Math.ceil(sortedPosts.length / itemsPerPage));
  }, [sortedPosts, noOfPages]);

  const onHandleChange = (event, value) => {
    setPage(value);
  };

  let pagePosts = sortedPosts?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  const mid = Math.ceil(pagePosts.length / 2);
  let showRows = [pagePosts.slice(0, mid), pagePosts.slice(mid)];
  return (
    <>
      <div className="blog__slider">
        {!loading &&
          showRows[0].length > 0 &&
          showRows.map((row, i) => (
            <Grid container spacing={1} key={i}>
              {row.map((item, j) => (
                <PostCard {...item} key={j} />
              ))}
            </Grid>
          ))}
      </div>
      <div style={{ display: "Grid", marginTop: "50px" }}>
        <Pagination
          count={noOfPages}
          variant="outlined"
          size="large"
          color="primary"
          page={page}
          onChange={onHandleChange}
          classes={{ ul: classes.paginator }}
        />
      </div>
    </>
  );
};
export default Slider;

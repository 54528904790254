export default {
  Docs: {
    CV: require("../assets/PersonalDocs/CV_Sabesan_Sathananthan.pdf"),
    Resume: require("../assets/PersonalDocs/Resume_Sabesan_Sathananthan.pdf"),
  },
  MySelf:
    "I'm Sabesan S., currently studying in the Department of Computer Science and Engineering in the University of Moratuwa, Sri Lanka. I studied my secondary education in J/Jaffna Hindu College, Sri Lanka and primary education in J/Jaffna Hindu Primary school, Sri Lanka. My research interests include Data Science, Artificial Intelligence, Computer Security and Software Architecture and Design. I am the founder of the team CodeZillas who emerged the finalists in the Asia Pacific IEEE SS12 innovation challenge and the winners of hackX, another innovation challenge held in the University of Kelaniya, Sri Lanka. The team CodeZillas which I led was selected at the Dialog Game Hunt to create an Edutainment Game for the mobile Android platform. The game was aimed at children who are weak in spelling. As part of this, we have partnered with Dialog Axiata PLC.",

  EmploymentDetails: [
    {
      CompanyName: "Dialog Gaming",
      Position: "Game Developer",
      StartDate: "2018 Dec",
      EndDate: "2019 Jan",
      Description:
        "Game Developer @ Dialog Gaming. Here I am creating and developing an Android mobile educational game which is titled: Spell Me.",
      url: "https://www.dialog.lk/browse/dialogGames.jsp",
    },
    {
      CompanyName: "Sysco LABS Technologies (PVT) LTD, Sri Lanka",
      Position: "Intern-Software Engineer",
      StartDate: "2019 Jun",
      EndDate: "2019 Dec",
      Description:
        "Worked as an intern-software engineer in the food services domain primarily working on a project facilitating interaction between the business and external vendors. Gained experience in full stack development, test scenarios, test case writing with React Js, TypeScript, Redux-Saga, Jest, Enzyme, Chai, Mocha and Java.",
      url: "https://syscolabs.lk/",
    },
    {
      CompanyName: "Hatchyard (PRIVATE) LIMITED, Sri Lanka",
      Position: "Trainee Software Engineer",
      StartDate: "2020 Apr",
      EndDate: "2020 Jun",
      Description:
        "Worked on a React based web application and feasibility analysis and initial training of an Arc Face Model using the MXNet framework for a smart face recognition module",
      url: "https://hatchyard.io/",
    },
    {
      CompanyName: "Sysco LABS Technologies (PVT) LTD, Sri Lanka",
      Position: "Software Engineer",
      StartDate: "2021 Feb",
      EndDate: "Present",
      Description:
        "I am currently working as a Software Engineer at Sysco LABS Technologies (PVT) LTD",
      url: "https://syscolabs.lk/",
    },
  ],
  EducationDetails: [
    {
      InstitutionName: "Jaffna Hindu College, Jaffna, Sri Lanka",
      Degree: "GCE Ordinary Level Examination",
      StartDate: "2007",
      EndDate: "2012",
      Description:
        "I sat for the G.C.E Ordinary Level Examination and scored 8 A passes and a B pass for the subjects Mathematics, Science, Information Technology, Tamil Literature, Hinduism, History, Commerce and Accounting, Tamil and English(B).",
    },
    {
      InstitutionName: "Jaffna Hindu College, Jaffna, Sri Lanka",
      Degree: "GCE Advanced Level Examination",
      StartDate: "2013",
      EndDate: "2015",
      Description:
        "I sat for the GCE Advanced Level Examination in the year 2015 in the physical science stream and scored 3 A passes for combined Mathematics, Chemistry and Physics. District Rank - 28, Island Rank - 281, z-score - 2.3163",
    },
    {
      InstitutionName: "University of Moratuwa, Moratuwa, Sri Lanka",
      Degree: "BSc Eng Hons in Computer Science & Engineering",
      StartDate: "2016",
      EndDate: "Present",
      Description:
        "Currently I am studying in the Department of Computer Science and Engineering in the Univeristy of Moratuwa.",
    },
  ],
  BlogDetails: [
    {
      BlogSite: "Medium",
      Position: "Technical Writer",
      StartDate: "2018 Mar",
      EndDate: "Present",
      Description:
        "Technical Writer @Medium. Medium provides an open platform  where readers find dynamic thinking, and where expert and undiscovered voices can share their writing on any topic.",
      url: "https://sabesan96.medium.com/",
    },
    {
      BlogSite: "Dev",
      Position: "Technical Writer",
      StartDate: "2019 Dec",
      EndDate: "Present",
      Description:
        "Technical Writer @Dev. DEV Community is a community of 605,211 amazing developers.We're a place where coders share, stay up-to-date and grow their careers. ",
      url: "https://dev.to/thesabesan",
    },
  ],
};

import React from "react";
import aboutData from "../static/AboutData";
import Timeline from "../components/Timeline";
import "../assets/styles/Timeline.scss";

export const AboutComponent = () => {
  const sortedEmploymentDetails = []
    .concat(aboutData.EmploymentDetails)
    .sort((a, b) => {
      let EndDateA = a.EndDate === "Present" ? new Date() : new Date(a.EndDate);
      let EndDateB = b.EndDate === "Present" ? new Date() : new Date(b.EndDate);
      let StartDateA = new Date(a.StartDate);
      let StartDateB = new Date(b.StartDate);
      return EndDateA - EndDateB === 0
        ? StartDateB - StartDateA
        : EndDateB - EndDateA;
    });
  const sortedEducationDetails = []
    .concat(aboutData.EducationDetails)
    .sort((a, b) => {
      let EndDateA = a.EndDate === "Present" ? new Date() : new Date(a.EndDate);
      let EndDateB = b.EndDate === "Present" ? new Date() : new Date(b.EndDate);
      let StartDateA = new Date(a.StartDate);
      let StartDateB = new Date(b.StartDate);
      return EndDateA - EndDateB === 0
        ? StartDateB - StartDateA
        : EndDateB - EndDateA;
    });
  const sortedBlogDetails = [].concat(aboutData.BlogDetails).sort((a, b) => {
    let EndDateA = a.EndDate === "Present" ? new Date() : new Date(a.EndDate);
    let EndDateB = b.EndDate === "Present" ? new Date() : new Date(b.EndDate);
    let StartDateA = new Date(a.StartDate);
    let StartDateB = new Date(b.StartDate);
    return EndDateA - EndDateB === 0
      ? StartDateB - StartDateA
      : EndDateB - EndDateA;
  });

  return (
    <div>
      <section id="resume" class="resume section-bg ">
        <div class="container">
          <div class="section-title">
            <div className="welcome_text">
              <h4>About Myself</h4>
            </div>
            <p className="no-margin-text p_40_bottom">{aboutData.MySelf}</p>
          </div>

          <div class="row p_40_bottom">
            <div class="col-lg-6">
              <h3 class="resume-title">Professional Experience</h3>
              {sortedEmploymentDetails.map((details, key) => (
                <Timeline
                  type="jop"
                  heading={details.Position}
                  startDate={details.StartDate}
                  endDate={details.EndDate}
                  place={details.CompanyName}
                  Description={details.Description}
                  url={details.url}
                />
              ))}
            </div>
            <div class="col-lg-6">
              <h3 class="resume-title">Education</h3>
              {sortedEducationDetails.map((details, key) => (
                <Timeline
                  type="education"
                  heading={details.Degree}
                  startDate={details.StartDate}
                  endDate={details.EndDate}
                  place={details.InstitutionName}
                  Description={details.Description}
                />
              ))}
              <h3 class="resume-title">Blogs or Videos </h3>
              {sortedBlogDetails.map((details, key) => (
                <Timeline
                  type="blog"
                  heading={details.Position}
                  startDate={details.StartDate}
                  endDate={details.EndDate}
                  place={details.BlogSite}
                  Description={details.Description}
                  url={details.url}
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* <!--================Testimonials Area =================--> */}
      <section className="feature_area testi_two p_80_footer">
        <div className="container">
          <div className="main_title">
            <h2>Download Documents</h2>
            <p>If you want my Resume or curriculum vitae click blow!</p>
          </div>
          <div className="button-group-area mt-40">
            <a
              className="banner_btn"
              href={aboutData.Docs.CV}
              target="_blank"
              rel="noopener noreferrer"
              role="button"
            >
              Download CV
              <i className="fas fa-download"> </i>
            </a>
            {"     "}
            <a
              className="banner_btn"
              href={aboutData.Docs.Resume}
              target="_blank"
              rel="noopener noreferrer"
              role="button"
            >
              Download Résumé
              <i className="fas fa-download"> </i>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

import React from "react";
import "../assets/styles/Timeline.scss";

const Timeline = ({
  type,
  heading,
  startDate,
  endDate,
  place,
  Description,
  url,
}) => {
  return (
    <div className="resume-item">
      <div className={type}>
        <h4 style={{ marginTop: "0px" }}>{heading}</h4>
        <h5>
          {startDate} - {endDate}
        </h5>
        {type === "jop" || type === "blog" ? (
          <a
            href={url}
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>
              <em>{place}</em>
            </p>
          </a>
        ) : (
          <p>
            <em>{place}</em>
          </p>
        )}

        <p>{Description}</p>
      </div>
    </div>
  );
};

export default Timeline;

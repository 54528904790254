import React, { useEffect, useState } from "react";
import { makeStyles, IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toTop: {
    zIndex: 2,
    position: "fixed",
    bottom: "2vh",
    backgroundColor: "#8490ff",
    color: "#fff",
    "&:hover, &.Mui-focusVisible": {
      transition: "0.3s",
      color: "#fff",
      backgroundColor: "#818cf5",
    },
    right: "1%",
  },
}));

const Scroll = ({ showBelow }) => {
  const classes = useStyles();
  const [show, setShow] = useState(showBelow ? false : true);

  const onHandleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, onHandleScroll);
      return () => window.removeEventListener(`scroll`, onHandleScroll);
    }
  });

  const onHandleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };
  return (
    <div>
      {show && (
        <IconButton onClick={onHandleClick} className={classes.toTop}>
          <i className="fas fa-arrow-up"></i>
        </IconButton>
      )}
    </div>
  );
};
export default Scroll;

import React from "react";
import projectDetails from "../../static/ProjectData";

export const ChatbotComponent = () => {
  const PageDatails = projectDetails.Projects[4].data;
  const parts = PageDatails.Description.split("\n");

  return (
    <section className="portfolio_details_area p_80_footer">
      <div className="container">
        <div className="portfolio_details_inner">
          <div className="row">
            <div className="col-md-6">
              <div className="left_img">
                <img className="img-fluid" src={PageDatails.ImageURL} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="portfolio_right_text">
                <h4>{PageDatails.Title}</h4>
                <p className="alignText">{PageDatails.ProjectIntro}</p>
                <ul className="list">
                  <li>
                    <span>Year</span>: {PageDatails.Year}
                  </li>
                  {PageDatails["Tools Used"] ? (
                    <li>
                      <span style={{ width: "auto" }}>
                        <h4 className="toolUsed">Tools Used</h4>
                      </span>
                    </li>
                  ) : null}
                  {PageDatails["Tools Used"].Software ? (
                    <li>
                      <span>Software</span>:{" "}
                      {PageDatails["Tools Used"].Software}
                    </li>
                  ) : null}
                  {PageDatails["Tools Used"].Hardware ? (
                    <li>
                      <span>Hardware</span>:{" "}
                      {PageDatails["Tools Used"].Hardware}
                    </li>
                  ) : null}
                  {PageDatails["Tools Used"].Environment ? (
                    <li>
                      <span>Environment</span>:{" "}
                      {PageDatails["Tools Used"].Environment}
                    </li>
                  ) : null}
                  {/* Start Document */}
                  <li>
                    <span>
                      <h5>Project</h5>
                    </span>
                    : Click the icon to watch the project
                  </li>
                  <a
                    href="https://github.com/sabesansathananthan/chatbot"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <font color="black">
                      <i className="fab fa-github-square customSize"></i>
                    </font>
                  </a>
                  {/* End Document */}
                </ul>
              </div>
            </div>
          </div>
          {parts.map((p, key) => (
            <p className="alignText" key={key}>
              {p}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
};

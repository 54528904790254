export default {
  FullName: "Sabesan Sathanathan",
  FirstName: "Sabesan",
  Pages: {
    Home: "Home",
    About: "About",
    Projects: "Projects",
    Blog: "Blog",
    Media: "Media",
    Contact: "Contact",
  },
};

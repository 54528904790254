import React from "react";
import projectData from "../static/ProjectData";
import ProjectCardComponent from "../components/ProjectCardComponent";
import { ProjectCategoryComponent } from "../components/ProjectCategoryComponent";
export class ProjectComponent extends React.Component {
  fullProjectsList = projectData.ProjectsReverse;

  state = {
    FilterValue: "",
  };

  breadCrumbLinks() {
    this.props.onHeaderClick();
  }

  OnFilterValue(event) {
    const classN = event.target.id;
    this.setState({ FilterValue: classN });
    event.preventDefault();
  }

  render() {
    const { FilterValue } = this.state;

    let FilterArr =
      FilterValue !== "All Categories" && FilterValue !== ""
        ? this.fullProjectsList.filter((val) => {
            return val.data.Category === FilterValue;
          })
        : this.fullProjectsList;

    return (
      <section className="projects_area p_80_footer">
        <div className="container">
          <div className="main_title">
            <h2>My Projects</h2>
            <p>{projectData.projectPageDescription}</p>

            <div className="projects_fillter">
              <ul className="filter list">
                {Object.values(projectData.ProjectTypes).map((val, key) => {
                  return (
                    <ProjectCategoryComponent
                      key={key}
                      val={val}
                      onClick={this.OnFilterValue.bind(this)}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
          <div
            className="projects_inner row"
            onClick={this.breadCrumbLinks.bind(this)}
          >
            {FilterArr.map((details, index) => {
              return (
                <ProjectCardComponent
                  key={index}
                  title={details.data.Title}
                  cardDescription={details.data.CardDescription}
                  category={details.data.Category}
                  img={details.data.ImageURL}
                />
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}
